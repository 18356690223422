import Link from 'next/link'
import React from 'react'
import { FaFacebookF, FaLinkedinIn, FaInstagram } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'

function Footer() {
    return (
        <div>
            <footer className="footer cpy-6 ">
                <div className="container">
                    <div className="footer-content-wrapper">
                        <div className="row">
                            <div className="col-md-6 col-lg-3">
                                <div className="footer-item">
                                    <div className="footer-logo">
                                        <Link href={'/'}>
                                            <img
                                                src="/img/home/footer-logo.png"
                                                alt=""
                                            />
                                        </Link>
                                    </div>
                                    <div className="footer-description">
                                        <p>
                                            One Country, One Card – Your
                                            Ultimate Access to a Connected
                                            Nation. One Tap, Endless
                                            Possibilities.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="footer-item">
                                    <ul className="footer-item-list">
                                        <li>
                                            <Link href="/about">About Us</Link>
                                        </li>
                                        <li>
                                            <Link href="/contact-us">
                                                Contact Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link href="/faq-question">
                                                FAQ
                                            </Link>
                                        </li>
                                        <li>
                                            <Link href="/terms-of-services">
                                                Terms & Conditions
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="footer-item">
                                    <ul className="footer-item-list">
                                        <li>
                                            <Link href="/register">
                                                <button className="custom-btn mb-2">
                                                    Register
                                                </button>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link href="/privacy-policy">
                                                Privacy Policy
                                            </Link>
                                        </li>

                                        <li>
                                            <Link href="/refound-policy">
                                                Cancellation & Refund Policy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link href="/cookie-policy">
                                                Cookie Policy
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="footer-item">
                                    <h3 className="social-title">
                                        Follow Link
                                    </h3>
                                    <ul className="social-lists-item">
                                        <li>
                                            <Link href="/">
                                                <span>
                                                    <FaFacebookF />
                                                </span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link href="/">
                                                <span>
                                                    <FaXTwitter />
                                                </span>
                                            </Link>
                                        </li>

                                        <li>
                                            <Link href="https://www.linkedin.com/company/one-country-one-card/?viewAsMember=true">
                                                <span>
                                                    <FaLinkedinIn />
                                                </span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link href="https://www.instagram.com/onecountryonecard?igsh=Ym42cTFhZHE4ODdn">
                                                <span>
                                                    <FaInstagram />
                                                </span>
                                            </Link>
                                        </li>
                                    </ul>
                                    <p>© 2024 All Rights Reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer
